<script setup>
const props = defineProps({
    indexCurrentEtape: {
        type: Number,
        default: 1
    }
});

const loaded = ref(false)

onMounted(()=> loaded.value = true)

const gamnedScript = computed(() => {
    if (process.client && Cookiebot?.consent.marketing) {
        if (loaded.value === true) {
            if (props.indexCurrentEtape === 0) {
                return "pixie('event', 'step_0_je_me_lance');";
            } else if (props.indexCurrentEtape === 1) {
                return "pixie('event', 'Step1Profil');";
            } else if (props.indexCurrentEtape === 2) {
                return "pixie('event', 'Step2Revenus');";
            } else if (props.indexCurrentEtape === 3) {
                return "pixie('event', 'Step3Charges');";
            } else if (props.indexCurrentEtape === 4) {
                return "pixie('event', 'step_4_impots');";
            }
        }
    }

    return "";
});

const gamnedNoScript = computed(() => {
    if (process.client && Cookiebot?.consent.marketing) {
        if (props.indexCurrentEtape === 0) {
            return "<img src=\"https://ib.adnxs.com/pixie?pi=1052db1d-49f5-445b-9833-2ec5cfd4b948&e=step_0_je_me_lance&script=0\" width=\"1\" height=\"1\" style=\"display:none\"/>";
        } else if (props.indexCurrentEtape === 1) {
            return "<img src=\"https://ib.adnxs.com/pixie?pi=1052db1d-49f5-445b-9833-2ec5cfd4b948&e=Step1Profil&script=0\" width=\"1\" height=\"1\" style=\"display:none\"/>";
        } else if (props.indexCurrentEtape === 2) {
            return "<img src=\"https://ib.adnxs.com/pixie?pi=1052db1d-49f5-445b-9833-2ec5cfd4b948&e=Step3Charges&script=0\" width=\"1\" height=\"1\" style=\"display:none\"/>";
        } else if (props.indexCurrentEtape === 3) {
            return "<img src=\"https://ib.adnxs.com/pixie?pi=1052db1d-49f5-445b-9833-2ec5cfd4b948&e=Step3Charges&script=0\" width=\"1\" height=\"1\" style=\"display:none\"/>";
        } else if (props.indexCurrentEtape === 4) {
            return "<img src=\"https://ib.adnxs.com/pixie?pi=1052db1d-49f5-445b-9833-2ec5cfd4b948&e=step_4_impots&script=0\" width=\"1\" height=\"1\" style=\"display:none\"/>";
        }
    }

    return "";
});

useHead({
    script: [
        { innerHTML: gamnedScript }
    ],
    noscript: [
        { innerHTML: gamnedNoScript }
    ]
})
</script>

<template>

</template>
